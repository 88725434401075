import type { Colord } from 'colord'
import { colord, extend } from 'colord'
// there is no extension for this import
// eslint-disable-next-line import/extensions
import mixPlugin from 'colord/plugins/mix'

import type { Hex } from '../components/color-picker/types'

extend([mixPlugin])

export const lighten = (color: Hex | Colord, amount?: number) => colord(color).lighten(amount)

export const setAlpha = (color: Hex | Colord, alpha?: number) => colord(color).alpha(alpha ?? 1)

export const readableColor = (color: Hex | Colord) => (colord(color).isDark() ? 'textInverted' : 'text')

export const mix = (color1: Hex, color2: Hex, ratio?: number): Hex => colord(color1).mix(color2, ratio).toHex()

export const isDark = (value: Colord | Hex) => colord(value).isDark()

export const isValid = (color: string) => colord(color).isValid()
