import { flatten } from 'remeda'

export const warning = (condition: boolean, ...messages: ReadonlyArray<string>) => {
    if (process.env.NODE_ENV === 'production' || !condition) {
        return
    }

    const warnings = flatten(messages.map((message) => [message, '\n']))

    // eslint-disable-next-line no-console
    console.warn(...warnings.slice(0, -1))

    // Throwing an error and catching it immediately to improve debugging
    // A consumer can use 'pause on caught exceptions'
    // https://github.com/facebook/react/issues/4216
    try {
        throw new Error(warnings.join(''))
    } catch {
        // do nothing
    }
}
